<template>
 
    <div class="width__form uk-modal-body">
      <h2 class="uk-modal-title uk-text-center">
        Информация о проведенной консультации
      </h2>
      <div class="">
        <div>
          <div class="uk-margin">
         <label
          ><input
            class="uk-radio"
            type="radio"
            uk-tooltip="title: Клиент закрепляется за агенством на срок до 3 месяцев(с момента получения уведомления)"
            checked
            value="Консультационная заявка"
            v-model="isChecked"
          /> Консультационная заявка
          </label
        >
      </div>
        </div>
        <div>
          <div class="uk-margin">
         <label
          ><input
            class="uk-radio"
            type="radio"
            uk-tooltip="title: Клиент закрепляется за агенством на срок до 3 месяцев(с момента получения уведомления)"
            checked
            v-model="isChecked"
            value="Полное спопровождение сделки"
          /> Полное спопровождение сделки
          </label
        >
      </div>
        </div>
        <div class="uk-margin">
         <label
          ><input
            class="uk-checkbox"
            type="checkbox"
            checked
            required
            v-model="isProject"
          /> Проект
          </label
        >
      </div>
      <div class="uk-margin">
         <label
          ><input
            class="uk-checkbox"
            type="checkbox"
            checked
            v-model="isConctruction"
          /> Строительство
          </label
        >
      </div>
      <div class="uk-margin">
         <label
          ><input
            class="uk-checkbox"
            type="checkbox"
            checked
            v-model="isBuy"
          /> Покупка готового
          </label
        >
      </div>
      </div>
      <h4>Специалист</h4>
      <div class="uk-margin ">
        <input
          v-model="modal_agency_name"
          class="uk-input"
          type="text"
          placeholder="Наименование партнера"
        />
      </div>
      <div class="uk-margin ">
        <input
          v-model="modal_agent_name"
          class="uk-input"
          type="text"
          required
          placeholder="ФИО специалиста"
        />
      </div>
      <div class="uk-margin ">
        <input
          v-model="modal_agent_phone"
          name="phone"
          class="uk-input"
          type="text"
          required
          placeholder="+7 (999) 999-99-99"
        />
      </div>
      <h4>Заказчик</h4>
      <div class="uk-margin ">
        <input
          v-model="modal_client_name"
          class="uk-input"
          type="text"
          required
          placeholder="ФИО"
        />
      </div>
      <div class="uk-margin">
        <input
          v-model="modal_client_phone"
          name="modal_client_phone"
          class="uk-input"
          type="text"
          required
          placeholder="+7 (999) 999-99-99"
        />
      </div>
      <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
        <label
          ><input
            v-model="hasGround"
            class="uk-checkbox"
            type="checkbox"
            checked
          />
          Наличие земельного участка</label
        >
      </div>
      <div class="uk-grid-small" uk-grid>
        <p class="uk-width-1-1">Стоимость</p>
        <div class="uk-width-1-2">
          <input
            v-model.number="price_from"
            class="uk-input"
            type="text"
            placeholder="От"
          />
        </div>
        <div class="uk-width-1-2">
          <input
            v-model.number="price_to"
            class="uk-input"
            type="text"
            placeholder="До"
          />
        </div>
      </div>
      <div class="uk-margin">
        <p class="uk-width-1-1">Форма оплаты</p>
        <select 
          v-model="form_payment"
          class="uk-select"
          required
          >
          <option>Наличные</option>
          <option>Ипотека</option>
          <option>Наличные + ипотека</option>
        </select>
      </div>
      <div class="uk-margin">
        <p class="uk-width-1-1">Ожидает звонка</p>
        <select 
          v-model="waiting_call"
          class="uk-select"
          required
          >
          <option>Клиент</option>
          <option>Риелтор</option>
        </select>
      </div>
      <div class="uk-margin ">
        <textarea
          v-model="description"
          class="uk-textarea"
          rows="3"
          placeholder="Краткое описание проекта"
        ></textarea>
      </div>
      <div class="uk-margin">
        <button
          class="uk-button uk-button-default uk-align-center green-button uk-margin-bottom"
          @click="consultationForm"
          :disabled="!isAccept"
        >
          Отправить
        </button>
        <label class="text-accept"
          ><input
            class="uk-checkbox"
            type="checkbox"
            checked
            required
            v-model="isAccept"
          /> 
          Нажимая кнопку "Отправить", вы соглашаетесь вы соглашаетесь на
          обработку ваших
          <a
            href="https://vevanta.com/politika-konfidencialnosti"
            target="_blank"
            class="green"
          >
            персональных данных</a
          ></label
        >
        <div class="uk-margin-small-top">
          <label class="text-accept"
          ><input
            class="uk-checkbox"
            type="checkbox"
            checked
            required
            v-model="isReceive"
          /> 
          Ознакомлен(-а) с регламентом работы закрпления Клиента за Агенством недвижимости
        </label
        >
        </div>
      </div>
    </div>
</template>

<script>
import mixin from "../mixins/index";

export default {
  mixins: [mixin],
  data() {
    return {
      modal_agency_name: "",
      modal_agent_name: "",
      modal_agent_phone: "",
      modal_agent_email: "",
      modal_client_name: "",
      modal_client_phone: "",
      hasGround: false,
      price_from: "",
      price_to: "",
      description: "",
      waiting_call: "",
      form_payment: "",


      isAccept: true,
      isReceive: true,
      isConsultation: false,
      isConctruction: false,
      isProject: false,
      isBuy: false,
      isChecked: '',
    };
  },
  watch: {
    modal_agent_phone(val) {
      this.phoneMask(val, "modal_agent_phone");
    },
    modal_client_phone(val) {
      this.phoneMask(val, "modal_client_phone");
    },
  },
  methods: {
    consultationForm() {
      document.querySelectorAll('input').forEach((item) => {
        if(item.value === '') {
          item.classList.add('error') 
        }
        if(item.value != '') {
          item.classList.remove('error') 
        }
      })
      document.querySelectorAll('select').forEach((item) => {
        if(item.value === '') {
          item.classList.add('error') 
        }
        if(item.value != '') {
          item.classList.remove('error') 
        }
  
      })
      if(this.modal_agency_name != ""
        && this.modal_agent_name != ""
        && this.modal_agent_phone != ""
        && this.modal_agent_email != ""
        && this.modal_client_name != ""
        && this.modal_client_phone != ""
        && this.price_from != ""
        && this.price_to != ""
        && this.description != ""
        && this.waiting_call != ""
        && this.form_payment != ""
        && this.isAccept === true
        && this.isChecked != ""
        && this.isReceive === true
        && this.isConctruction === true
        || this.isProject === true
        || this.isBuy === true
        ) {
          this.request({
        form: `Информация о консультации. АН ${this.modal_agency_name}`,
        name: this.modal_client_name,
        email: this.modal_agent_email,
        phone: this.modal_client_phone,
        msg: `
              Уведомить о консультации. Веванта Партнеры<br>
              Наименование партнера: ${this.modal_agency_name};<br>
              Специалист: ${this.modal_agent_name};<br>
              Телефон специалиста: <a href="tel:${this.modal_agent_phone}">${this.modal_agent_phone}</a>;<br>
              Электронная почта специалиста: <a href="mailto:${this.modal_agent_email}">${this.modal_agent_email}</a>;<br>
              Заказчик: ${this.modal_client_name};<br>
              Телефон заказчика: <a href="tel:${this.modal_client_phone}">${this.modal_client_phone}</a>;<br>
              Наличие земельного участка: ${this.hasGround ? "Да" : "Нет"};<br>
              Стоимость: от ${this.price_from} до ${this.price_to};<br>
              Краткое описание: ${this.description};<br>
              Отправить на консультацию КП "Веванта Лайф: ${this.isConsultation ? "Да" : "Нет"};<br>
              ${this.isChecked};<br>
              -Проект: ${this.isConctruction ? "Да" : "Нет"};<br>
              -Строительство: ${this.isProject ? "Да" : "Нет"};<br>
              -Покупка готового: ${this.isBuy ? "Да" : "Нет"};<br>
              Форма оплаты: ${this.form_payment};<br>
              Ожидает звонка: ${this.waiting_call};<br>
              `,
        source_id: 13,
        assigned_by_id: 78,
      }).then(() => {
        this.modal_agency_name = "";
        this.modal_agent_name = "";
        this.modal_agent_email = "";
        this.modal_agent_phone = "";
        this.modal_client_name = "";
        this.modal_client_phone = "";
        this.hasGround = false;
        this.price_from = "";
        this.price_to = "";
        this.description = "";
      });
        }
    },
  },
};
</script>

<style scoped>
.text-accept {
  font-size: 14px;
}
.width__form {
  margin: 0 auto;
  max-width: 600px;
}
.uk-select, .uk-select:focus {
    border-color: #78beb5;
}
.error {
  border-color: #e92525
}
</style>
